/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

Coded by Diego Spampinato

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// @mui material components

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

// Material Dashboard 2 React base styles
import darkColor from "assets/theme-dark/base/colors";
import lightColor from "assets/theme/base/colors";
import boxShadows from "assets/theme/base/boxShadows";

// Image
import greenField from "assets/images/green-fields.png";
import HorizontalBar from "examples/customComponents/horizontalBar";

// Data
import Moral from "./Moral";

function Insight({ prediction }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { background } = darkColor;
  const { white } = lightColor;
  const { md } = boxShadows;

  return (
    <MDBox
      sx={{
        boxShadow: md,
        backgroundColor: darkMode
          ? ({ functions: { rgba } }) => rgba(background.card, 0.9)
          : ({ functions: { rgba } }) => rgba(white.main, 0.9),
        color: darkMode ? white.main : background.card,
      }}
      borderRadius="lg"
      justifyContent="center"
      alignItems="center"
    >
      <MDBox
        sx={{
          backgroundImage: darkMode
            ? ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0),
                  rgba(gradients.dark.state, 0)
                )}, url(${greenField})`
            : ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0),
                  rgba(gradients.dark.state, 0)
                )}, url(${greenField})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        borderRadius="md"
        display="flex"
      >
        <Grid container>
          <Grid item xs={12} md={12} lg={12} m={1.5}>
            <MDBox display="flex" justifyContent="space-between" m={1}>
              <MDTypography variant="caption" fontWeight="light">
                {prediction.arena}
              </MDTypography>
              <MDTypography variant="caption" fontWeight="light">
                {prediction.date}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="row" justifyContent="space-around">
              <MDBox display="flex" flexDirection="row" alignItems="center">
                <MDBox
                  component="img"
                  src={prediction.home_logo}
                  alt={prediction.home_name}
                  width="4rem"
                  height="4rem"
                  m={0.5}
                />
              </MDBox>
              <MDBox display="flex" flexDirection="row" alignItems="center">
                <MDBox
                  component="img"
                  src={prediction.away_logo}
                  alt={prediction.away_name}
                  width="4rem"
                  height="4rem"
                  m={0.5}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" flexDirection="row" justifyContent="space-around" pt={4}>
              <MDTypography variant="h5" fontWeight="bold">
                {prediction.home_name}
              </MDTypography>
              <MDTypography variant="h5" fontWeight="bold">
                {prediction.away_name}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox display="flex" flexDirection="row" justifyContent="space-around" mt={-5} pb={1}>
        <MDBox pt={5} display="flex" flexDirection="column" justifyContent="flex-start">
          <MDTypography variant="h5" fontWeight="bold">
            <MDTypography variant="caption" fontWeight="medium">
              Points
            </MDTypography>
            &nbsp;{prediction.home_point}
          </MDTypography>
          <MDTypography variant="h5" fontWeight="bold">
            <MDTypography variant="caption" fontWeight="medium">
              Rank
            </MDTypography>
            &nbsp;{prediction.home_rank}
          </MDTypography>
          <MDTypography variant="caption" fontWeight="medium" mt={1}>
            Moment
          </MDTypography>
          <Moral value={prediction.moral_h} />
        </MDBox>
        <MDBox display="column" mt={4} pb={2.1}>
          <HorizontalBar
            value={prediction.h_wperc}
            color="index"
            variant="rtl"
            height={6}
            label="Wins"
          />
          <HorizontalBar
            value={prediction.h_dperc}
            color="index"
            variant="rtl"
            height={6}
            label="Draws"
          />
          <HorizontalBar
            value={prediction.h_lperc}
            color="index"
            variant="rtl"
            height={6}
            label="Losses"
          />
          <HorizontalBar
            value={prediction.power_h_atk}
            color="index"
            variant="rtl"
            height={6}
            label="Atk"
          />
          <HorizontalBar
            value={prediction.power_h_def}
            color="index"
            variant="rtl"
            height={6}
            label="Def"
          />
        </MDBox>
        <MDBox display="column" mt={4} pb={2.1}>
          <HorizontalBar value={prediction.a_wperc} color="index" height={6} label="Wins" />
          <HorizontalBar value={prediction.a_dperc} color="index" height={6} label="Draws" />
          <HorizontalBar value={prediction.a_lperc} color="index" height={6} label="Losses" />
          <HorizontalBar value={prediction.power_a_atk} color="index" height={6} label="Atk" />
          <HorizontalBar value={prediction.power_a_def} color="index" height={6} label="Def" />
        </MDBox>
        <MDBox pt={5} display="flex" flexDirection="column" justifyContent="flex-start">
          <MDTypography variant="h5" fontWeight="bold">
            <MDTypography variant="caption" fontWeight="medium">
              Points
            </MDTypography>
            &nbsp;{prediction.away_point}
          </MDTypography>
          <MDTypography variant="h5" fontWeight="bold">
            <MDTypography variant="caption" fontWeight="medium">
              Rank
            </MDTypography>
            &nbsp;{prediction.away_rank}
          </MDTypography>
          <MDTypography variant="caption" fontWeight="medium" mt={1}>
            Moment
          </MDTypography>
          <Moral value={prediction.moral_a} />
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default Insight;
