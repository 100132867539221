/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

Coded by Diego Spampinato

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// @mui material components

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

// Material Dashboard 2 React base styles
import darkColor from "assets/theme-dark/base/colors";
import lightColor from "assets/theme/base/colors";
import boxShadows from "assets/theme/base/boxShadows";

// Data

function ClockMatchNull() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { background } = darkColor;
  const { white } = lightColor;
  const { md } = boxShadows;

  return (
    <MDBox
      sx={{
        boxShadow: md,
        backgroundColor: darkMode
          ? ({ functions: { rgba } }) => rgba(background.card, 0.9)
          : ({ functions: { rgba } }) => rgba(white.main, 0.9),
        color: darkMode ? white.main : background.card,
      }}
      borderRadius="md"
      display="flex"
    >
      <Grid container mx={2} my={2}>
        <Grid item xs={12} md={12} lg={12}>
          <MDBox display="flex" justifyContent="center" alignItems="center">
            <MDTypography variant="h6" fontWeight="bold">
              NO MATCHES IN PROGRAM
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default ClockMatchNull;
