/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// Material Dashboard 2 React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;
const { white, success } = colors;

const linearProgress = {
  styleOverrides: {
    root: {
      height: pxToRem(8),
      borderRadius: borderRadius.sm,
      overflow: "hidden",
      position: "relative",
    },

    colorPrimary: {
      backgroundColor: white.main,
    },

    colorSecondary: {
      backgroundColor: success.state,
    },

    bar: {
      height: pxToRem(8),
      backgroundColor: success.state,
      borderRadius: borderRadius.xs,
      position: "absolute",
      transform: `translate(0, 100) !important`,
      transition: "width 0.4s ease !important",
    },
  },
};

export default linearProgress;
