/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

Coded by Diego Spampinato

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { useEffect, useState } from "react";

// @mui material components

// @mui material components
import useMediaQuery from "@mui/material/useMediaQuery";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LinearProgress from "@mui/material/LinearProgress";

// Material Dashboard 2 React components

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

// Material Dashboard 2 React base styles
import boxShadows from "assets/theme/base/boxShadows";

// image
import backgroundImageDay from "assets/images/bg-reset-inside-det.jpeg";
import Insights from "@mui/icons-material/Insights";
import Ball from "@mui/icons-material/SportsSoccer";
import Dns from "@mui/icons-material/Dns";

// Custom React components
import Overview from "./Overview";
import Insight from "./Insight";
import Prediction from "./Prediction";
import ClockMatch from "./ClockMatch";

// Data

const listView = [
  {
    viewId: "0",
    viewName: "Overview",
    icon: "Dns",
  },
  {
    viewId: "1",
    viewName: "Insight",
    icon: "Insights",
  },
  {
    viewId: "2",
    viewName: "Prediction",
    icon: "Ball",
  },
];

function TabPanelView(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        <MDBox sx={{ p: 0 }}>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

TabPanelView.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function CardPrediction({ key, prediction, leagueLogo }) {
  const mediumViewport = useMediaQuery("(min-width:768px)");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { md } = boxShadows;
  const [valueView, setValueView] = useState(0);
  const [isFetching, setIsFetching] = useState(true);

  const handleChangeTabsView = (event, newValue) => {
    setValueView(newValue);
  };

  useEffect(() => {
    setInterval(() => {
      setIsFetching(false);
    }, 1000);

    return () => {};
  }, []);

  return (
    <MDBox>
      {isFetching ? (
        <MDBox sx={{ display: "flex", justifyContent: "center" }}>
          <MDBox width="100%" m={1}>
            <LinearProgress />
          </MDBox>
        </MDBox>
      ) : (
        <Card key={key}>
          <MDBox
            sx={{
              flexWrap: "wrap",
              boxShadow: md,
              backgroundImage: darkMode
                ? ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${backgroundImageDay})`
                : ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0),
                      rgba(gradients.dark.state, 0)
                    )}, url(${backgroundImageDay})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            justifyContent="space-between"
            alignItems="center"
            borderRadius="md"
          >
            <MDBox sx={{ flexGrow: 1, display: { xs: "column", md: "flex" } }}>
              <Grid container>
                <Grid item xs={12} md={9} lg={9} p={1}>
                  <ClockMatch prediction={prediction} leagueLogo={leagueLogo} />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <MDBox mt={1}>
                    <Tabs
                      orientation={mediumViewport ? "vertical" : "horizontal"}
                      variant="scrollable"
                      value={valueView}
                      onChange={handleChangeTabsView}
                      aria-label="tabsView"
                      selectionFollowsFocus
                    >
                      {listView.map(
                        (item) =>
                          (item.icon === "Dns" && (
                            <Tab
                              sx={{
                                justifyContent: "start",
                                width: "99%",
                                minWidth: "20px",
                              }}
                              key={item.viewId}
                              disableFocusRipple
                              label={item.viewName}
                              icon={<Dns fontSize="small" />}
                              iconPosition="start"
                              {...a11yProps(item.viewId)}
                            />
                          )) ||
                          (item.icon === "Insights" && (
                            <Tab
                              sx={{
                                justifyContent: "start",
                                width: "99%",
                                minWidth: "20px",
                              }}
                              key={item.viewId}
                              disableFocusRipple
                              label={item.viewName}
                              icon={<Insights fontSize="small" />}
                              iconPosition="start"
                              {...a11yProps(item.viewId)}
                            />
                          )) ||
                          (item.icon === "Ball" && (
                            <Tab
                              sx={{
                                justifyContent: "start",
                                width: "99%",
                                minWidth: "20px",
                              }}
                              key={item.viewId}
                              disableFocusRipple
                              label={item.viewName}
                              icon={<Ball fontSize="small" />}
                              iconPosition="start"
                              {...a11yProps(item.viewId)}
                            />
                          ))
                      )}
                    </Tabs>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={9} lg={9}>
                  <MDBox>
                    {listView.map((item, i) => (
                      <TabPanelView value={valueView} index={i} key={item.viewId}>
                        <MDBox sx={{ flexGrow: 1, display: "flex" }}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                              {item.viewName === "Overview" && (
                                <MDBox>
                                  <Overview />
                                </MDBox>
                              )}
                              {item.viewName === "Insight" && (
                                <MDBox m={1} mt={0}>
                                  <Insight prediction={prediction} />
                                </MDBox>
                              )}
                              {item.viewName === "Prediction" && (
                                <MDBox m={1} mt={0}>
                                  <Prediction prediction={prediction} />
                                </MDBox>
                              )}
                            </Grid>
                          </Grid>
                        </MDBox>
                      </TabPanelView>
                    ))}
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </Card>
      )}
    </MDBox>
  );
}

// Setting default values for the props of LeagueStats
CardPrediction.defaultProps = {
  key: 0,
};

// Typechecking props for the LeagueDets
CardPrediction.propTypes = {
  key: PropTypes.number,
  prediction: PropTypes.arrayOf(PropTypes.object).isRequired,
  leagueLogo: PropTypes.string.isRequired,
};

export default CardPrediction;
