/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// Material Dashboard 2 React helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import rgba from "assets/theme/functions/rgba";

const { size, fontWeightRegular, fontWeightMedium } = typography;
const { borderWidth, borderRadius } = borders;
const { success, dark, grey, white } = colors;

const tab = {
  styleOverrides: {
    root: {
      backgroundColor: white.main,
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      flex: "1 1 auto",
      textAlign: "center",
      justifyContent: "start",
      maxWidth: "unset !important",
      minWidth: 140,
      minHeight: "unset !important",
      fontSize: size.xs,
      fontWeight: fontWeightRegular,
      textTransform: "none",
      lineHeight: "inherit",
      padding: pxToRem(1),
      color: `${dark.main} !important`,
      opacity: "1 !important",
      border: `${borderWidth[1]} solid ${grey[600]}`,
      borderBottom: `${borderWidth[4]} solid ${grey[600]}`,
      borderRadius: borderRadius.md,
      margin: pxToRem(2),

      "&.Mui-selected": {
        backgroundColor: success.state,
        border: `${borderWidth[1]} solid ${success.main}`,
        borderBottom: `${borderWidth[4]} solid ${success.state}`,
        fontWeight: fontWeightMedium,

        ":hover": {
          backgroundColor: rgba(white.main, 0.2),
          borderBottom: `${borderWidth[4]} solid ${success.state}`,
        },
      },

      ":hover": {
        backgroundColor: rgba(white.main, 1),
        borderBottom: `${borderWidth[4]} solid ${success.state}`,
      },

      ":focus": {
        border: `${borderWidth[1]} solid ${success.main}`,
        borderBottom: `${borderWidth[4]} solid ${success.main}`,
        paddingRight: pxToRem(2),
      },

      "& .material-icons, .material-icons-round": {
        marginBottom: "0 !important",
        marginRight: pxToRem(6),
      },

      "& svg": {
        marginBottom: "0 !important",
        marginRight: pxToRem(6),
      },
    },

    labelIcon: {
      paddingTop: pxToRem(4),
    },
  },
};

export default tab;
