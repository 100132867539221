/**
=========================================================
* ooGoal - v4.1.0
=========================================================

* Product Page: https:/oogoal.com
* Copyright 2022 Onza Technologies
* Coder: Diego Spampinato - OnzaSan

=========================================================

* The above copyright must be included
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Material Dashboard 2 React custom components
import TabLeague from "layouts/teams/components/TabLeague";

// Data

// @mui icons

// Images

function Teams() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={12} lg={12}>
            <TabLeague key="tabLeague"> </TabLeague>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Teams;
