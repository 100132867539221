/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

Coded by Diego Spampinato

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// @mui material components

// @mui material components

// Material Dashboard 2 React components

// Material Dashboard 2 React contexts

// Material Dashboard 2 React base styles

// Image

// Custom React components

// Data

function OverviewNull() {
  return null;
}

export default OverviewNull;
