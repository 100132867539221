/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

Coded by Diego Spampinato

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { useMaterialUIController } from "context";

// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import boxShadows from "assets/theme/base/boxShadows";
import borders from "assets/theme/base/borders";
import pxToRem from "assets/theme/functions/pxToRem";

// Custom styles for MDProgress

function HorizontalBar({ value, color, baseColor, variant, height, label }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { borderRadius } = borders;
  const { indicators } = colors;
  const { lg } = boxShadows;

  let backgroundBar = indicators[color].state;
  let backgroundTrack = indicators[baseColor].state;
  let valueBar = value;
  let valueTrack = 100 - value;
  const heightBar = pxToRem(height);
  const heightTrack = pxToRem(height);

  if (darkMode) {
    backgroundBar = indicators[color].state;
    backgroundTrack = indicators.basedark.state;
  }

  if (variant === "rtl") {
    valueBar = 100 - valueBar;
    valueTrack = value;
    backgroundBar = indicators.basewhite.state;
    backgroundTrack = indicators[color].state;
    if (darkMode) {
      backgroundBar = indicators.basedark.state;
      backgroundTrack = indicators[color].state;
    }
  }

  return (
    <MDBox display="column" width="100%" mt={1}>
      <MDBox display="flex" justifyContent="space-between">
        <MDTypography variant="caption" fontWeight="regular">
          {label}
        </MDTypography>
        <MDTypography variant="caption" fontWeight="regular">
          {value}%
        </MDTypography>
      </MDBox>
      <MDBox
        boxShadow={lg}
        display="flex"
        bgColor={variant === "rtl" ? backgroundBar : backgroundTrack}
        p="1px"
        borderRadius={borderRadius.md}
        opacity={0.95}
      >
        <MDBox
          display="inline-flex"
          width="100%"
          mx="1px"
          justifyContent={variant === "rtl" ? "start" : "end"}
        >
          <MDBox bgColor={backgroundBar} width={`${valueBar}%`} height={heightBar} />
          <MDBox bgColor={backgroundTrack} width={`${valueTrack}%`} height={heightTrack} />
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of MDProgress
HorizontalBar.defaultProps = {
  variant: "straight",
  color: "normal",
  baseColor: "basewhite",
  value: 100,
  height: "10px",
  label: " ",
};

// Typechecking props for the MDProgress
HorizontalBar.propTypes = {
  variant: PropTypes.oneOf(["straight", "rtl"]),
  color: PropTypes.oneOf([
    "verygood",
    "good",
    "normal",
    "bad",
    "verybad",
    "white",
    "dark",
    "index",
    "basedark",
    "basewhite",
  ]),
  baseColor: PropTypes.oneOf([
    "verygood",
    "good",
    "normal",
    "bad",
    "verybad",
    "white",
    "dark",
    "index",
    "basedark",
    "basewhite",
  ]),
  value: PropTypes.number,
  height: PropTypes.number,
  label: PropTypes.string,
};

export default HorizontalBar;
