/**
=========================================================
* ooGoal - v4.1.0
=========================================================

* Product Page: https:/oogoal.com
* Copyright 2022 Onza Technologies
* Coder: ****

=========================================================

* The above copyright must be included
*/

import io from "socket.io-client";

const socket = io("https://oogoal.com:4444");

export default socket;
