/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

Coded by Diego Spampinato

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// @mui material components

// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React contexts

// Image
import verybadG from "assets/images/bad_g.png";
import badG from "assets/images/weak_g.png";
import normalG from "assets/images/normal_g.png";
import goodG from "assets/images/good_g.png";
import verygoodG from "assets/images/great_g.png";
import verybad from "assets/images/bad.png";
import bad from "assets/images/weak.png";
import normal from "assets/images/normal.png";
import good from "assets/images/good.png";
import verygood from "assets/images/great.png";

function Moral({ value }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox
        component="img"
        src={value <= 20 ? verybad : verybadG}
        alt="very bad"
        width={value <= 20 ? "1rem" : "0.5rem"}
        height={value <= 20 ? "1rem" : "0.5rem"}
        m={0.5}
      />
      <MDBox
        component="img"
        src={value > 20 && value <= 40 ? bad : badG}
        alt="bad"
        width={value > 20 && value <= 40 ? "1rem" : "0.5rem"}
        height={value > 20 && value <= 40 ? "1rem" : "0.5rem"}
        m={0.5}
      />
      <MDBox
        component="img"
        src={value > 40 && value <= 60 ? normal : normalG}
        alt="normal"
        width={value > 40 && value <= 60 ? "1rem" : "0.5rem"}
        height={value > 40 && value <= 60 ? "1rem" : "0.5rem"}
        m={0.5}
      />
      <MDBox
        component="img"
        src={value > 60 && value <= 80 ? good : goodG}
        alt="good"
        width={value > 60 && value <= 80 ? "1rem" : "0.5rem"}
        height={value > 60 && value <= 80 ? "1rem" : "0.5rem"}
        m={0.5}
      />
      <MDBox
        component="img"
        src={value > 80 ? verygood : verygoodG}
        alt="very good"
        width={value > 80 ? "1rem" : "0.5rem"}
        height={value > 80 ? "1rem" : "0.5rem"}
        m={0.5}
      />
    </MDBox>
  );
}

export default Moral;
