/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

Coded by Diego Spampinato

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TableStandings from "examples/Tables/TableStandings";
import TableTopscores from "examples/Tables/TableTopscores";
import TableStorage from "examples/Tables/TableStorage";

// Material Dashboard 2 React base styles
import boxShadows from "assets/theme/base/boxShadows";
import borders from "assets/theme/base/borders";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

// Material Dashboard 2 React base styles
import darkColor from "assets/theme-dark/base/colors";
import lightColor from "assets/theme/base/colors";

// image
import backgroundImage from "assets/images/bg-profile.jpeg";

// custom components
import LeagueStats from "./LeagueStats";
import LeagueDets from "./LeagueDets";

// Data
import dataTableStandings from "../../../layouts/leagues/data/dataTableStandings";
import dataTableTopscores from "../../../layouts/leagues/data/dataTableTopscores";
import dataTableStorage from "../../../layouts/leagues/data/dataTableStorage";

const steps = [
  {
    label: "leagueStandings",
  },
  {
    label: "leagueTopscores",
  },
  {
    label: "leagueStorage",
  },
];

function CardLeague({
  tableStandings,
  tableTopscores,
  tableStorage,
  leagueId,
  title,
  country,
  date,
  icon,
  correct,
  wrong,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { background, dark } = darkColor;
  const { white } = lightColor;
  const { md } = boxShadows;
  const { borderRadius } = borders;
  const { columnsStandings, rowsStandings } = dataTableStandings(tableStandings, leagueId);
  const { columnsTopscores, rowsTopscores } = dataTableTopscores(tableTopscores, leagueId);
  const { columnsStorage, rowsStorage } = dataTableStorage(tableStorage, leagueId);

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Card
      sx={{
        backgroundImage: darkMode
          ? ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0),
                rgba(gradients.dark.state, 1)
              )}, url(${backgroundImage})`
          : ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 1),
                rgba(gradients.dark.state, 0)
              )}, url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <MDBox
        sx={{
          backgroundColor: darkMode
            ? ({ functions: { rgba } }) => rgba(background.card, 0)
            : ({ functions: { rgba } }) => rgba(white.main, 0),
        }}
        borderRadius="md"
        justifyContent="center"
        alignItems="center"
      >
        <MDBox
          sx={{ flexWrap: "wrap" }}
          display="flex"
          borderRadius="md"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <LeagueDets icon={icon} title={title} country={country} date={date} />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <LeagueStats
                icon={icon}
                title="ooGoal score"
                correct={correct}
                wrong={wrong}
                exactPerc={Math.round((correct * 100) / (correct + wrong))}
                percentage={{
                  color: "success",
                  label: "good predictions",
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox mx={0.5}>
        <MobileStepper
          sx={{
            backgroundColor: darkMode
              ? ({ functions: { rgba } }) => rgba(background.card, 0.9)
              : ({ functions: { rgba } }) => rgba(white.main, 0.9),
          }}
          variant="progress"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="large" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              <MDTypography
                sx={{ color: darkMode ? white.main : dark.main }}
                variant="button"
                fontWeight="light"
                display="flex"
              >
                Next <KeyboardArrowRight />
              </MDTypography>
            </Button>
          }
          backButton={
            <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
              <MDTypography
                sx={{ color: darkMode ? white.main : dark.main }}
                variant="button"
                fontWeight="light"
                display="flex"
              >
                <KeyboardArrowLeft />
                Back
              </MDTypography>
            </Button>
          }
        />
        <SwipeableViews
          axis="x"
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {steps.map((step, index) => (
            <div key={step.label}>
              {Math.abs(activeStep - index) <= 2 && step.label === "leagueStandings" ? (
                <MDBox
                  sx={{
                    boxShadow: md,
                    borderRadius: borderRadius.md,
                    backgroundColor: darkMode
                      ? ({ functions: { rgba } }) => rgba(background.card, 0.9)
                      : ({ functions: { rgba } }) => rgba(white.main, 0.9),
                  }}
                  justifyContent="center"
                  alignItems="center"
                  m={1}
                >
                  <TableStandings
                    table={{ columnsStandings, rowsStandings }}
                    isSorted={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              ) : null}
              {Math.abs(activeStep - index) <= 2 && step.label === "leagueTopscores" ? (
                <MDBox
                  sx={{
                    boxShadow: md,
                    borderRadius: borderRadius.md,
                    backgroundColor: darkMode
                      ? ({ functions: { rgba } }) => rgba(background.card, 0.9)
                      : ({ functions: { rgba } }) => rgba(white.main, 0.9),
                  }}
                  justifyContent="center"
                  alignItems="center"
                  m={1}
                >
                  <TableTopscores
                    table={{ columnsTopscores, rowsTopscores }}
                    isSorted={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              ) : null}
              {Math.abs(activeStep - index) <= 2 && step.label === "leagueStorage" ? (
                <MDBox
                  sx={{
                    boxShadow: md,
                    borderRadius: borderRadius.md,
                    backgroundColor: darkMode
                      ? ({ functions: { rgba } }) => rgba(background.card, 0.85)
                      : ({ functions: { rgba } }) => rgba(white.main, 0.85),
                  }}
                  justifyContent="center"
                  alignItems="center"
                  m={1}
                >
                  <TableStorage
                    table={{ columnsStorage, rowsStorage }}
                    isSorted={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              ) : null}
            </div>
          ))}
        </SwipeableViews>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props
CardLeague.defaultProps = {
  date: {
    start: "not available",
    end: "not available",
  },
  correct: 0,
  wrong: 1,
};

// Typechecking props
CardLeague.propTypes = {
  tableStandings: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableTopscores: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableStorage: PropTypes.arrayOf(PropTypes.object).isRequired,
  leagueId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  date: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
  correct: PropTypes.number,
  wrong: PropTypes.number,
};

export default CardLeague;
