/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function data(table, teamId) {
  const Match = ({ teamHome, teamAway }) => (
    <MDBox display="column" alignItems="left" lineHeight={0}>
      <MDBox>
        <MDTypography display="block" variant="caption" fontWeight="bold">
          {teamHome}
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDTypography display="block" variant="caption" fontWeight="bold">
          {teamAway}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Score = ({ scoreHome, scoreAway }) => (
    <MDBox display="column" alignItems="left" lineHeight={0}>
      <MDBox ml={2}>
        <MDTypography display="block" variant="caption" fontWeight="bold">
          {scoreHome}
        </MDTypography>
      </MDBox>
      <MDBox ml={2}>
        <MDTypography display="block" variant="caption" fontWeight="bold">
          {scoreAway}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const rowsStorage = [];
  table.forEach((tableRow) =>
    teamId === tableRow.home_id || teamId === tableRow.away_id
      ? rowsStorage.push({
          round: (
            <MDBox>
              <MDTypography display="block" variant="caption" fontWeight="bold">
                {tableRow.round_home}
              </MDTypography>
            </MDBox>
          ),
          match: <Match teamHome={tableRow.home_name} teamAway={tableRow.away_name} />,
          score: <Score scoreHome={tableRow.full_goalh} scoreAway={tableRow.full_goala} />,
          prediction: (
            <MDBox>
              <MDTypography component="a" href="#" variant="caption" fontWeight="medium">
                {tableRow.full_prediction}
              </MDTypography>
            </MDBox>
          ),
        })
      : null
  );

  return {
    columnsStorage: [
      { Header: "round", accessor: "round", align: "center" },
      { Header: "match", accessor: "match", align: "left" },
      { Header: "score", accessor: "score", align: "left" },
      { Header: "prediction", accessor: "prediction", align: "center" },
    ],

    rowsStorage,
  };
}
