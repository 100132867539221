/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

Coded by Diego Spampinato

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { useState, useEffect, useRef, useMemo } from "react";

// @mui material components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React base styles

// Data
import EditIcon from "@mui/icons-material/Edit";
import ReplayIcon from "@mui/icons-material/Replay";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import TimerIcon from "@mui/icons-material/Timer";

const listView = [
  {
    viewId: "0",
    viewName: "Final Time",
    icon: "TimerOff",
  },
  {
    viewId: "1",
    viewName: "Half Time",
    icon: "Timer",
  },
];

function TabPanelView(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        <MDBox>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

TabPanelView.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function PredictionPanel({ prediction }) {
  const [valueView, setValueView] = useState(0);
  const [enable, setEnable] = useState(true);
  const [bFull1, setBFull1] = useState(prediction.full_prono === prediction.home_name && true);
  const [bFullX, setBFullX] = useState(prediction.full_prono === "X" && true);
  const [bFull2, setBFull2] = useState(prediction.full_prono === prediction.away_name && true);
  const [bFullU, setBFullU] = useState(prediction.full_unov === "Under" && true);
  const [bFullO, setBFullO] = useState(prediction.full_unov === "Over" && true);
  const [bFullG, setBFullG] = useState(prediction.full_gnog === "Goal" && true);
  const [bFullNG, setBFullNG] = useState(prediction.full_gnog === "No Goal" && true);

  // initialize the prediction to send
  const predictiones = useMemo(() => {
    return{
      fixtureId: prediction.fixtureId,
      date: prediction.date,
      league_name: prediction.league_name,
      home_name: prediction.home_name,
      away_name: prediction.away_name,
      home_logo: prediction.home_logo,
      away_logo: prediction.away_logo,
      full1: bFull1,
      fullX: bFullX,
      full2: bFull2,
      fullU: bFullU,
      fullO: bFullO,
      fullG: bFullG,
      fullNG: bFullNG
    }
  }, [prediction, bFull1, bFullX, bFull2, bFullU, bFullO, bFullG, bFullNG]);

  const memBFull1 = useRef(bFull1);
  const memBFullX = useRef(bFullX);
  const memBFull2 = useRef(bFull2);
  const memBFullU = useRef(bFullU);
  const memBFullO = useRef(bFullO);
  const memBFullG = useRef(bFullG);
  const memBFullNG = useRef(bFullNG);

  const initialRender = useRef(true);

  useEffect(() => {
    // prevent firt render dispatch
  if (initialRender.current) {
    initialRender.current = false;
  }
  }, [predictiones, bFull1, bFull2, bFullU, bFullG]);

  const resetPrediction = () => {
    setBFull1(memBFull1.current);
    setBFullX(memBFullX.current);
    setBFull2(memBFull2.current);
    setBFullU(memBFullU.current);
    setBFullO(memBFullO.current);
    setBFullG(memBFullG.current);
    setBFullNG(memBFullNG.current);
  };

  const handle1Full = () => {
    setBFull1(true);
    setBFullX(false);
    setBFull2(false);
  };
  const handleXFull = () => {
    setBFull1(false);
    setBFullX(true);
    setBFull2(false);
  };
  const handle2Full = () => {
    setBFull1(false);
    setBFullX(false);
    setBFull2(true);
  };

  const handleUFull = () => {
    setBFullU(true);
    setBFullO(false);
  };

  const handleOFull = () => {
    setBFullU(false);
    setBFullO(true);
  };

  const handleGFull = () => {
    setBFullG(true);
    setBFullNG(false);
  };

  const handleNGFull = () => {
    setBFullG(false);
    setBFullNG(true);
  };

  const handleChangeTabsView = (event, newValue) => {
    setValueView(newValue);
  };

  return (
    <MDBox mx={1}>
      <MDBox display="flex" mt={-2} justifyContent="space-between">
        <MDBox display="flex" alignItems="center">
          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={valueView}
            onChange={handleChangeTabsView}
            aria-label="tabsView"
            selectionFollowsFocus
            sx={{ boxShadow: "none", background: "transparent" }}
          >
            {listView.map(
              (item) =>
                (item.icon === "TimerOff" && (
                  <Tab
                    sx={{
                      justifyContent: "center",
                      minWidth: "30px",
                      typography: "smallCaption",
                    }}
                    key={item.viewId}
                    disableFocusRipple
                    label={item.viewName}
                    icon={<TimerOffIcon fontSize="small" />}
                    iconPosition="start"
                    {...a11yProps(item.viewId)}
                  />
                )) ||
                (item.icon === "Timer" && (
                  <Tab
                    sx={{
                      justifyContent: "center",
                      minWidth: "30px",
                      typography: "microCaption",
                    }}
                    key={item.viewId}
                    disableFocusRipple
                    label={item.viewName}
                    icon={<TimerIcon fontSize="small" />}
                    iconPosition="start"
                    {...a11yProps(item.viewId)}
                  />
                ))
            )}
          </Tabs>
        </MDBox>
        <MDBox display="flex" justifyContent="center" mt={1} mr={1}>
          <MDButton
            iconOnly
            size="small"
            variant="custom"
            color="success"
            onClick={() => setEnable(false)}
          >
            <MDTypography variant="caption" fontWeight="bold">
              <EditIcon size="small" />
            </MDTypography>
          </MDButton>
          <MDButton
            iconOnly
            size="small"
            variant="custom"
            color="success"
            onClick={resetPrediction}
          >
            <MDTypography variant="caption" fontWeight="bold">
              <ReplayIcon size="small" />
            </MDTypography>
          </MDButton>
        </MDBox>
      </MDBox>
      {listView.map((item, i) => (
        <TabPanelView value={valueView} index={i} key={item.viewId}>
          {item.viewName === "Final Time" && (
            <MDBox mt={-2}>
              <MDBox display="flex" alignItems="center" justifyContent="space-around" my={1}>
                <MDButton
                  size="small"
                  variant={bFull1 ? "selected" : "custom"}
                  color="success"
                  disabled={bFull1 ? false : enable}
                  onClick={!enable ? handle1Full : null}
                >
                  <MDBox
                    component="img"
                    src={prediction.home_logo}
                    alt={prediction.home_name}
                    width="1rem"
                    height="1rem"
                    m={0.5}
                  />
                  <MDTypography variant="caption" fontWeight="light">
                    @ {prediction.odds1}
                  </MDTypography>
                </MDButton>
                <MDButton
                  size="small"
                  variant={bFullX ? "selected" : "custom"}
                  color="success"
                  disabled={bFullX ? false : enable}
                  onClick={!enable ? handleXFull : null}
                >
                  <MDTypography variant="button" fontWeight="bold">
                    X&nbsp;
                  </MDTypography>
                  <MDTypography variant="caption" fontWeight="light">
                    @ {prediction.oddsX}
                  </MDTypography>
                </MDButton>
                <MDButton
                  size="small"
                  variant={bFull2 ? "selected" : "custom"}
                  color="success"
                  disabled={bFull2 ? false : enable}
                  onClick={!enable ? handle2Full : null}
                >
                  <MDBox
                    component="img"
                    src={prediction.away_logo}
                    alt={prediction.away_name}
                    width="1rem"
                    height="1rem"
                    m={0.5}
                  />
                  <MDTypography variant="caption" fontWeight="light">
                    @ {prediction.odds2}
                  </MDTypography>
                </MDButton>
              </MDBox>
              <MDBox display="flex" alignItems="center" justifyContent="space-evenly" my={2}>
                <MDButton
                  size="small"
                  variant={bFullU ? "selected" : "custom"}
                  color="success"
                  disabled={bFullU ? false : enable}
                  onClick={!enable ? handleUFull : null}
                >
                  <MDTypography variant="caption" fontWeight="light">
                  @ {prediction.oddsU2_5}
                  </MDTypography>
                </MDButton>
                <MDTypography variant="caption" fontWeight="bold">
                  Under/Over 2.5
                </MDTypography>
                <MDButton
                  size="small"
                  variant={bFullO ? "selected" : "custom"}
                  color="success"
                  disabled={bFullO ? false : enable}
                  onClick={!enable ? handleOFull : null}
                >
                  <MDTypography variant="caption" fontWeight="light">
                  @ {prediction.oddsO2_5}
                  </MDTypography>
                </MDButton>
              </MDBox>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                my={1}
                pb={1.5}
              >
                <MDButton
                  size="small"
                  variant={bFullG ? "selected" : "custom"}
                  color="success"
                  disabled={bFullG ? false : enable}
                  onClick={!enable ? handleGFull : null}
                >
                  <MDTypography variant="caption" fontWeight="light">
                    @ NA
                  </MDTypography>
                </MDButton>
                <MDTypography variant="caption" fontWeight="bold">
                  Goal/NoGoal
                </MDTypography>
                <MDButton
                  size="small"
                  variant={bFullNG ? "selected" : "custom"}
                  color="success"
                  disabled={bFullNG ? false : enable}
                  onClick={!enable ? handleNGFull : null}
                >
                  <MDTypography variant="caption" fontWeight="light">
                    @ NA
                  </MDTypography>
                </MDButton>
              </MDBox>
            </MDBox>
          )}
          {item.viewName === "Half Time" && (
            <MDBox mt={-2}>
              <MDBox display="flex" alignItems="center" justifyContent="space-around" my={1}>
                <MDButton
                  size="small"
                  variant={bFull1 ? "selected" : "custom"}
                  color="success"
                  disabled={bFull1 ? false : enable}
                  onClick={!enable ? handle1Full : null}
                >
                  <MDBox
                    component="img"
                    src={prediction.home_logo}
                    alt={prediction.home_name}
                    width="1rem"
                    height="1rem"
                    m={0.5}
                  />
                  <MDTypography variant="caption" fontWeight="light">
                    @ {prediction.odds1}
                  </MDTypography>
                </MDButton>
                <MDButton
                  size="small"
                  variant={bFullX ? "selected" : "custom"}
                  color="success"
                  disabled={bFullX ? false : enable}
                  onClick={!enable ? handleXFull : null}
                >
                  <MDTypography variant="button" fontWeight="bold">
                    X&nbsp;
                  </MDTypography>
                  <MDTypography variant="caption" fontWeight="light">
                    @ {prediction.oddsX}
                  </MDTypography>
                </MDButton>
                <MDButton
                  size="small"
                  variant={bFull2 ? "selected" : "custom"}
                  color="success"
                  disabled={bFull2 ? false : enable}
                  onClick={!enable ? handle2Full : null}
                >
                  <MDBox
                    component="img"
                    src={prediction.away_logo}
                    alt={prediction.away_name}
                    width="1rem"
                    height="1rem"
                    m={0.5}
                  />
                  <MDTypography variant="caption" fontWeight="light">
                    @ {prediction.odds2}
                  </MDTypography>
                </MDButton>
              </MDBox>
              <MDBox display="flex" alignItems="center" justifyContent="space-evenly" my={2}>
                <MDButton
                  size="small"
                  variant={bFullU ? "selected" : "custom"}
                  color="success"
                  disabled={bFullU ? false : enable}
                  onClick={!enable ? handleUFull : null}
                >
                  <MDTypography variant="caption" fontWeight="light">
                    @ NA
                  </MDTypography>
                </MDButton>
                <MDTypography variant="caption" fontWeight="bold">
                  Under/Over 2.5
                </MDTypography>
                <MDButton
                  size="small"
                  variant={bFullO ? "selected" : "custom"}
                  color="success"
                  disabled={bFullO ? false : enable}
                  onClick={!enable ? handleOFull : null}
                >
                  <MDTypography variant="caption" fontWeight="light">
                    @ NA
                  </MDTypography>
                </MDButton>
              </MDBox>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                my={1}
                pb={1.5}
              >
                <MDButton
                  size="small"
                  variant={bFullG ? "selected" : "custom"}
                  color="success"
                  disabled={bFullG ? false : enable}
                  onClick={!enable ? handleGFull : null}
                >
                  <MDTypography variant="caption" fontWeight="light">
                    @ NA
                  </MDTypography>
                </MDButton>
                <MDTypography variant="caption" fontWeight="bold">
                  Goal/NoGoal
                </MDTypography>
                <MDButton
                  size="small"
                  variant={bFullNG ? "selected" : "custom"}
                  color="success"
                  disabled={bFullNG ? false : enable}
                  onClick={!enable ? handleNGFull : null}
                >
                  <MDTypography variant="caption" fontWeight="light">
                    @ NA
                  </MDTypography>
                </MDButton>
              </MDBox>
            </MDBox>
          )}
        </TabPanelView>
      ))}
    </MDBox>
  );
}

export default PredictionPanel;
