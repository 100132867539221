/**
=========================================================
* ooGoal - v4.1.0
=========================================================

* Product Page: https:/oogoal.com
* Copyright 2022 Onza Technologies
* Coder: Diego Spampinato - OnzaSan

=========================================================

* The above copyright must be included
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import socket from "service/socketIO/socket";

// @mui material components
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LinearProgress from "@mui/material/LinearProgress";

// Material Dashboard 2 React components
import useMediaQuery from "@mui/material/useMediaQuery";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import CardLeague from "examples/Cards/CardLeague";

// @mui icons

// Images

function TabPanelLeague(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        <MDBox sx={{ p: 0 }}>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

TabPanelLeague.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabLeague() {
  const mediumViewport = useMediaQuery("(min-width:768px)");
  const [valueLeague, setValueLeague] = useState(0);
  const [listLeagues, setListLeagues] = useState([]);
  const [listStandings, setListStandings] = useState([]);
  const [listTopscores, setListTopscores] = useState([]);
  const [listStorage, setListStorage] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const handleChangeTabsLeague = (event, newValue) => {
    setValueLeague(newValue);
  };

  useEffect(() => {
    socket.on("ackStorage", (data) => {
      setListStorage(data);
      setInterval(() => {
        setIsFetching(false);
      }, 1000);
    });
    socket.on("ackTopscores", (data) => {
      setListTopscores(data);
      socket.emit("syncStorage");
    });
    socket.on("ackStandings", (data) => {
      setListStandings(data);
      socket.emit("syncTopscores");
    });
    socket.on("ackLeagues", (data) => {
      setListLeagues(data);
      socket.emit("syncStandings");
    });

    const syncLeagues = () => {
      socket.emit("syncLeagues");
    };

    syncLeagues();
    return () => {
      socket.off("ackLeagues");
      socket.off("ackTeams");
      socket.off("ackStandings");
      socket.off("syncTopscores");
      socket.off("syncStorage");
    };
  }, []);

  return (
    <MDBox p={1}>
      {isFetching ? (
        <MDBox sx={{ display: "flex", justifyContent: "center" }}>
          <MDBox width="100%" m={1}>
            <LinearProgress />
          </MDBox>
        </MDBox>
      ) : (
        <MDBox sx={{ flexGrow: 1, display: { xs: "column", md: "flex" } }}>
          <Grid container>
            <Grid item xs={12} md={3} lg={3}>
              <Tabs
                orientation={mediumViewport ? "vertical" : "horizontal"}
                variant="scrollable"
                value={valueLeague}
                onChange={handleChangeTabsLeague}
                aria-label="tabsLeague"
                selectionFollowsFocus
              >
                {listLeagues.map((item) => (
                  <Tab
                    key={item.league_name}
                    disableFocusRipple
                    label={item.league_name}
                    icon={
                      <MDBox
                        component="img"
                        src={item.ws_urlLogo}
                        alt={item.league_name}
                        width="1rem"
                        pl={0.5}
                      />
                    }
                    iconPosition="start"
                    {...a11yProps(item.league_id)}
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid item xs={12} md={9} lg={9}>
              {listLeagues.map((item, i) => (
                <TabPanelLeague value={valueLeague} index={i} key={item.league_id}>
                  <MDBox p={1} pt={0} sx={{ flexGrow: 1, display: "flex" }}>
                    <Grid container>
                      <Grid item xs={12} md={12} lg={12}>
                        <MDBox mb={1.5}>
                          <CardLeague
                            tableStandings={listStandings}
                            tableTopscores={listTopscores}
                            tableStorage={listStorage}
                            leagueId={item.league_id}
                            icon={
                              <MDBox
                                component="img"
                                src={item.ws_urlLogo}
                                alt={item.league_id}
                                width="100%"
                                height="100%"
                              />
                            }
                            title={item.league_name}
                            country={item.nation_name}
                            date={{
                              start: item.date_start,
                              end: item.date_end,
                            }}
                            correct={item.correct}
                            wrong={item.wrong}
                          />
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </TabPanelLeague>
              ))}
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

export default TabLeague;
