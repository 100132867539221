/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import boxShadows from "assets/theme/base/boxShadows";

// Material Dashboard 2 React helper functions
import rgba from "assets/theme/functions/rgba";
import pxToRem from "assets/theme/functions/pxToRem";
import linearGradient from "assets/theme/functions/linearGradient";

const { borderRadius } = borders;
const { success, white } = colors;
const { colored, md } = boxShadows;

const tabs = {
  styleOverrides: {
    root: {
      position: "relative",
      backgroundColor: rgba(white.main, 0.9),
      minHeight: "unset",
      padding: pxToRem(8),
      margin: pxToRem(10),
      marginTop: pxToRem(0),
      marginBottom: pxToRem(10),
      boxShadow: md,
      borderRadius: borderRadius.lg,
    },

    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10,
    },

    fixed: {
      overflow: "unset !important",
      overflowX: "unset !important",
    },

    indicator: {
      width: "100%",
      backgroundImage: linearGradient(success.main, success.state),
      height: "100%",
      transition: "all 200ms ease",
      borderRadius: borderRadius.md,
      boxShadow: colored.success,
    },
  },
};

export default tabs;
