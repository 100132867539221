/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

export default function data(table, leagueId) {
  const Player = ({ image, namePlayer, nameTeam }) => (
    <MDBox display="flex" alignItems="center" lineHeight={0}>
      <MDAvatar src={image} name={namePlayer} size="xs" />
      <MDBox ml={2}>
        <MDTypography display="block" variant="caption" fontWeight="bold">
          {namePlayer}
        </MDTypography>
        <MDTypography variant="caption">{nameTeam}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const rowsTopscores = [];
  table.forEach((tableRow, i) =>
    tableRow.league_id === leagueId
      ? rowsTopscores.push({
          rank: (
            <MDBox>
              <MDTypography display="block" variant="caption" fontWeight="bold">
                {i + 1}
              </MDTypography>
            </MDBox>
          ),
          player: (
            <Player
              image={tableRow.ws_urlLogo}
              namePlayer={tableRow.playerName}
              nameTeam={tableRow.teamName}
            />
          ),
          scored: (
            <MDTypography component="a" href="#" variant="caption" fontWeight="medium">
              {tableRow.goalScored}
            </MDTypography>
          ),
          pscored: (
            <MDTypography component="a" href="#" variant="caption" fontWeight="medium">
              {tableRow.penaltyScored}
            </MDTypography>
          ),
          pmissed: (
            <MDTypography component="a" href="#" variant="caption" fontWeight="medium">
              {tableRow.penaltyMissed}
            </MDTypography>
          ),
          played: (
            <MDTypography component="a" href="#" variant="caption" fontWeight="medium">
              {tableRow.played}
            </MDTypography>
          ),
          minute: (
            <MDTypography component="a" href="#" variant="caption" fontWeight="medium">
              {tableRow.minutePlayed}
            </MDTypography>
          ),
        })        
      : null
  );

  return {
    columnsTopscores: [
      { Header: "", accessor: "rank", align: "center" },
      { Header: "", accessor: "player", align: "left" },
      { Header: "g", accessor: "scored", align: "center" },
      { Header: "ps", accessor: "pscored", align: "center" },
      { Header: "pm", accessor: "pmissed", align: "center" },
      { Header: "mp", accessor: "played", align: "center" },
      { Header: "mm", accessor: "minute", align: "center" },
    ],

    rowsTopscores,
  };
}
