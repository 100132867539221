/**
=========================================================
* ooGoal - v4.1.0
=========================================================

* Product Page: https:/oogoal.com
* Copyright 2022 Onza Technologies
* Coder: Diego Spampinato - OnzaSan

=========================================================

* The above copyright must be included
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import socket from "service/socketIO/socket";

// @mui material components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LinearProgress from "@mui/material/LinearProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React contexts

// Material Dashboard 2 React base styles

// Material Dashboard 2 React example components
import CardPrediction from "examples/Cards/CardPrediction";
import CardNull from "examples/Cards/CardNull";

// Material Dashboard 2 React custom components

// Data

// @mui icons
import TodayIcon from "@mui/icons-material/Today";
import ScheduleIcon from "@mui/icons-material/Schedule";
// Images

async function makeTab(numDays) {
  const list = [];
  const numbers = Array.from(Array(numDays), (_, index) => index);
  numbers.forEach((number) => {
    const date = new Date();
    date.setDate(date.getDate() + number);
    const dateStart = date.setHours(0, 0, 0, 0);
    const dateEnd = date.setHours(23, 59, 59, 999);
    const year = date.getFullYear();
    const month = `${0}${date.getMonth() + 1}`.slice(-2);
    const day = `${0}${date.getDate()}`.slice(-2);
    const dateTime = `${day}/${month}/${year}`;
    const dayObj = {
      day: dateTime,
      start: dateStart,
      end: dateEnd,
    };
    list.push(dayObj);
  });

  return list;
}

function TabPanelDay(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        <MDBox sx={{ p: 0 }}>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

TabPanelDay.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function PredictionsByDate({ numDay }) {
  const mediumViewport = useMediaQuery("(min-width:768px)");
  const [valueDay, setValueDay] = useState(0);
  const [listDays, setListDays] = useState([]);
  const [listPredictions, setListPredictions] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const handleChangeTabsDay = (event, newValue) => {
    setValueDay(newValue);
  };

  useEffect(() => {
    socket.on("ackPredictionsByDate", (data) => {
      setListPredictions(data);
      setIsFetching(false);
    });

    const syncPredictionsByDate = () => {
      socket.emit("syncPredictionsByDate");
    };

    makeTab(numDay).then((item) => setListDays(item));

    syncPredictionsByDate();
    return () => {
      socket.off("ackPredictionsByDate");
    };
  }, [numDay]);
  return (
    <MDBox p={1}>
      {isFetching ? (
        <MDBox sx={{ display: "flex", justifyContent: "center" }}>
          <MDBox width="100%" m={1}>
            <LinearProgress />
          </MDBox>
        </MDBox>
      ) : (
        <MDBox sx={{ flexGrow: 1, display: { xs: "column", md: "flex" } }}>
          <Grid item xs={12} md={3} lg={3}>
            <Tabs
              orientation={mediumViewport ? "vertical" : "horizontal"}
              variant="scrollable"
              scrollButtons="auto"
              value={valueDay}
              onChange={handleChangeTabsDay}
              aria-label="days tab"
              selectionFollowsFocus
            >
              {listDays.map((item, i) => (
                <Tab
                  key={item.day}
                  disableFocusRipple
                  label={item.day}
                  icon={
                    i === 0 ? <TodayIcon fontSize="small" /> : <ScheduleIcon fontSize="small" />
                  }
                  iconPosition="start"
                  {...a11yProps(item.day)}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            {listDays.map((item, i) => (
              <TabPanelDay value={valueDay} index={i} key={item.day}>
                <MDBox p={1} pt={0} sx={{ flexGrow: 1, display: "flex" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox mb={2}>
                        {listPredictions.filter(
                          (predic) => predic.timestamp >= item.start && predic.timestamp <= item.end
                        ).length > 0 ? (
                          listPredictions.map(
                            (item2, j) =>
                              item2.timestamp >= item.start &&
                              item2.timestamp <= item.end && (
                                <MDBox mb={3}>
                                  <CardPrediction
                                    key={j}
                                    index={j}
                                    prediction={item2}
                                    leagueLogo={item2.league_logo}
                                  />
                                </MDBox>
                              )
                          )
                        ) : (
                          <CardNull />
                        )}
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </TabPanelDay>
            ))}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

export default PredictionsByDate;
