/**
=========================================================
* ooGoal - v4.1.0
=========================================================

* Product Page: https:/oogoal.com
* Copyright 2022 Onza Technologies
* Coder: Diego Spampinato - OnzaSan

=========================================================

* The above copyright must be included
*/

import { useState } from "react";

// @material-ui core components
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Material Dashboard 2 React custom components
import PredictionsByDate from "layouts/oogoal/components/PredictionsByDate";
import PredictionsByLeague from "layouts/oogoal/components/PredictionsByLeague";

// @mui icons

// Images

function Oogoal() {
  const [byDate, setByDate] = useState(true);
  const [valueDay, setValueDay] = useState(7);
  const handleByDate = () => {
    setByDate(!byDate);
  };
  const handleChange = (event) => {
    setValueDay(event.target.value);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox display="flex" justifyContent="flex-start">
              <MDBox pl={2} display="flex" alignItems="center" justifyContent="space-around">
                <MDTypography variant="smallCaption" fontWeight="light">
                  show
                </MDTypography>
                <Select
                  labelId="number-days-selector"
                  id="day-selector"
                  value={valueDay}
                  onChange={handleChange}
                  autoWidth
                  label="days"
                  variant="standard"
                >
                  <MenuItem value={1}>Today &nbsp;</MenuItem>
                  <MenuItem value={3}>3 days</MenuItem>
                  <MenuItem value={7}>7 days</MenuItem>
                </Select>
                <Switch checked={!byDate} onChange={handleByDate} />
                <MDTypography variant="button" fontWeight="regular">
                  Leagues
                </MDTypography>
              </MDBox>
            </MDBox>
            {byDate ? (
              <PredictionsByDate numDay={valueDay}> </PredictionsByDate>
            ) : (
              <PredictionsByLeague> </PredictionsByLeague>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Oogoal;
