/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

export default function data(table, leagueId) {
  const Team = ({ image, nameTeam, nameLeague }) => (
    <MDBox display="flex" alignItems="center" lineHeight={0}>
      <MDAvatar src={image} name={nameTeam} size="xs" />
      <MDBox ml={2}>
        <MDTypography display="block" variant="caption" fontWeight="bold">
          {nameTeam}
        </MDTypography>
        <MDTypography variant="caption">{nameLeague}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const rowsStandings = [];
  table.forEach((tableRow) =>
    tableRow.league_id === leagueId
      ? rowsStandings.push({
          rank: (
            <MDBox>
              <MDTypography display="block" variant="caption" fontWeight="bold">
                {tableRow.ws_rank}
              </MDTypography>
            </MDBox>
          ),
          team: (
            <Team
              image={tableRow.ws_urlLogo}
              nameTeam={tableRow.ws_team_name}
              nameLeague={tableRow.ws_league_name}
            />
          ),
          point: (
            <MDTypography component="a" href="#" variant="caption" fontWeight="medium">
              {tableRow.point}
            </MDTypography>
          ),
          win: (
            <MDTypography component="a" href="#" variant="caption" fontWeight="medium">
              {tableRow.t_win}
            </MDTypography>
          ),
          draw: (
            <MDTypography component="a" href="#" variant="caption" fontWeight="medium">
              {tableRow.t_draw}
            </MDTypography>
          ),
          loss: (
            <MDTypography component="a" href="#" variant="caption" fontWeight="medium">
              {tableRow.t_loss}
            </MDTypography>
          ),
          Gscored: (
            <MDTypography component="a" href="#" variant="caption" fontWeight="medium">
              {tableRow.t_atk_goal}
            </MDTypography>
          ),
          Gtaken: (
            <MDTypography component="a" href="#" variant="caption" fontWeight="medium">
              {tableRow.t_def_goal}
            </MDTypography>
          ),
        })
      : null
  );

  return {
    columnsStandings: [
      { Header: "", accessor: "rank", align: "center" },
      { Header: "", accessor: "team", align: "left" },
      { Header: "p", accessor: "point", align: "center" },
      { Header: "w", accessor: "win", align: "center" },
      { Header: "d", accessor: "draw", align: "center" },
      { Header: "l", accessor: "loss", align: "center" },
      { Header: "gs", accessor: "Gscored", align: "center" },
      { Header: "gt", accessor: "Gtaken", align: "center" },
    ],

    rowsStandings,
  };
}
