/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

Coded by Diego Spampinato

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import boxShadows from "assets/theme/base/boxShadows";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

// Material Dashboard 2 React base styles
import darkColor from "assets/theme-dark/base/colors";
import lightColor from "assets/theme/base/colors";

function LeagueDets({ icon, title, country, date }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { background } = darkColor;
  const { white } = lightColor;
  const { md } = boxShadows;
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return (
    <MDBox
      sx={{
        boxShadow: md,
        backgroundColor: darkMode
          ? ({ functions: { rgba } }) => rgba(background.card, 0.9)
          : ({ functions: { rgba } }) => rgba(white.main, 0.9),
      }}
      borderRadius="md"
      m={1.5}
      p={1}
      py={1}
    >
      <MDBox display="flex" justifyContent="space-around">
        <MDBox textAlign="left" lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="bold">
            {title}
          </MDTypography>
          <MDBox display="column">
            <MDTypography component="p" variant="caption" display="flex">
              <MDTypography component="span" variant="caption" fontWeight="light">
                start: {new Date(date.start).toLocaleDateString("en-US", DATE_OPTIONS)}
              </MDTypography>
            </MDTypography>
            <MDTypography component="p" variant="caption" display="flex">
              <MDTypography variant="caption" fontWeight="light">
                end: {new Date(date.end).toLocaleDateString("en-US", DATE_OPTIONS)}
              </MDTypography>
            </MDTypography>
            <MDTypography component="p" variant="caption" display="flex">
              <MDTypography variant="caption" fontWeight="light">
                Country: {country}
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          variant="gradient"
          bgColor="success"
          color="dark"
          borderRadius="xxl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="3.5rem"
          height="3.5rem"
          mt={-2}
        >
          <Icon fontSize="large" color="inherit">
            {icon}
          </Icon>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of LeagueStats
LeagueDets.defaultProps = {
  date: {
    start: "not available",
    end: "not available",
  },
};

// Typechecking props for the LeagueDets
LeagueDets.propTypes = {
  title: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  date: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default LeagueDets;
