/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

Coded by Diego Spampinato

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import boxShadows from "assets/theme/base/boxShadows";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

// Material Dashboard 2 React base styles
import darkColor from "assets/theme-dark/base/colors";
import lightColor from "assets/theme/base/colors";

function LeagueStats({ color, exactPerc, title, percentage, icon, correct, wrong }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { background } = darkColor;
  const { white } = lightColor;
  const { md } = boxShadows;
  return (
    <MDBox
      sx={{
        boxShadow: md,
        backgroundColor: darkMode
          ? ({ functions: { rgba } }) => rgba(background.card, 0.9)
          : ({ functions: { rgba } }) => rgba(white.main, 0.9),
      }}
      borderRadius="md"
      m={1.5}
      p={1}
      py={1}
    >
      <MDBox display="flex" justifyContent="space-around">
        <MDBox
          variant="gradient"
          bgColor="success"
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xxl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="3.5rem"
          height="3.5rem"
          mt={-2}
        >
          <Icon fontSize="large" color="inherit">
            {icon}
          </Icon>
        </MDBox>
        <MDBox>
          <MDTypography component="p" variant="h4" fontWeight="bold" color={percentage.color}>
            {exactPerc} %
          </MDTypography>
        </MDBox>
        <MDBox textAlign="left" lineHeight={1.25} ml={1}>
          <MDTypography variant="h6" fontWeight="bold">
            {title}
          </MDTypography>
          <MDBox display="column">
            <MDTypography component="p" variant="caption" display="flex">
              <MDTypography variant="caption" fontWeight="light">
                {percentage.label}
              </MDTypography>
            </MDTypography>
            <MDTypography component="p" variant="caption" display="flex">
              <MDTypography component="span" variant="caption" fontWeight="light">
                correct: {correct}
              </MDTypography>
            </MDTypography>
            <MDTypography component="p" variant="caption" display="flex">
              <MDTypography variant="caption" fontWeight="light">
                wrong: {wrong}
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of LeagueStats
LeagueStats.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the LeagueStats
LeagueStats.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  exactPerc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
  correct: PropTypes.number.isRequired,
  wrong: PropTypes.number.isRequired,
};

export default LeagueStats;
