/**
=========================================================
* ooGoal - v4.1.0
=========================================================

* Product Page: https:/oogoal.com
* Copyright 2022 Onza Technologies
* Coder: Diego Spampinato - OnzaSan

=========================================================

* The above copyright must be included
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import socket from "service/socketIO/socket";

// @mui material components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LinearProgress from "@mui/material/LinearProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React contexts

// Material Dashboard 2 React base styles

// Material Dashboard 2 React example components
import CardPrediction from "examples/Cards/CardPrediction";
import CardNull from "examples/Cards/CardNull";

// Material Dashboard 2 React custom components

// Data

// @mui icons

// Images

function TabPanelLeague(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        <MDBox sx={{ p: 0 }}>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

TabPanelLeague.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function PredictionsByLeagues() {
  const mediumViewport = useMediaQuery("(min-width:768px)");
  const [valueLeague, setValueLeague] = useState(0);
  const [listLeagues, setListLeagues] = useState([]);
  const [listPredictions, setListPredictions] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const handleChangeTabsLeague = (event, newValue) => {
    setValueLeague(newValue);
  };

  useEffect(() => {
    socket.on("ackPredictionsByLeagues", (data) => {
      setListPredictions(data);
      console.log(data);
      setIsFetching(false);
    });

    socket.on("ackLeagues", (data) => {
      setListLeagues(data);
      console.log(data);
      socket.emit("syncPredictionsByLeagues");
    });

    const syncLeagues = () => {
      socket.emit("syncLeagues");
    };

    syncLeagues();
    return () => {
      socket.off("ackLeagues");
      socket.off("ackPredictionsByLeagues");
    };
  }, []);

  return (
    <MDBox p={1}>
      {isFetching ? (
        <MDBox sx={{ display: "flex", justifyContent: "center" }}>
          <MDBox width="100%" m={1}>
            <LinearProgress />
          </MDBox>
        </MDBox>
      ) : (
        <MDBox sx={{ flexGrow: 1, display: { xs: "column", md: "flex" } }}>
          <Grid item xs={12} md={3} lg={3}>
            <Tabs
              orientation={mediumViewport ? "vertical" : "horizontal"}
              variant="scrollable"
              scrollButtons="auto"
              value={valueLeague}
              onChange={handleChangeTabsLeague}
              aria-label="leagues tab"
              selectionFollowsFocus
            >
              {listLeagues.map((item) => (
                <Tab
                  key={item.league_name}
                  disableFocusRipple
                  label={item.league_name}
                  icon={
                    <MDBox
                      component="img"
                      src={item.ws_urlLogo}
                      alt={item.league_name}
                      width="1rem"
                      pl={0.5}
                    />
                  }
                  iconPosition="start"
                  {...a11yProps(item.league_id)}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            {listLeagues.map((item, i) => (
              <TabPanelLeague value={valueLeague} index={i} key={item.league_id}>
                <MDBox p={1} pt={0} sx={{ flexGrow: 1, display: "flex" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox mb={2}>
                        {listPredictions.filter((predic) => predic.league_id === item.league_id)
                          .length > 0 ? (
                          listPredictions.map(
                            (item2, j) =>
                              item2.league_id === item.league_id && (
                                <MDBox mb={3}>
                                  <CardPrediction
                                    key={item2.fixtureId}
                                    index={j}
                                    prediction={item2}
                                    leagueLogo={item2.league_logo}
                                  />
                                </MDBox>
                              )
                          )
                        ) : (
                          <CardNull />
                        )}
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </TabPanelLeague>
            ))}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

export default PredictionsByLeagues;
